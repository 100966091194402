<template>
  <v-container fluid>
    <v-row v-if="profile == 2">
      <v-col cols="12" md="4">
        <v-card class="">
          <v-card-title>
            <h4>Ajout de recours</h4>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field v-model="recourse.object" label="Objet"></v-text-field>
            <v-autocomplete
              v-if="this.$store.getters['Auth/get_all_legal_form']"
              v-model="recourse.recourse_type"
              :items="this.$store.getters['Auth/get_all_legal_form'].recourse_type"
              item-text="description"
              item-value="id"
              outlined
              label="Choisir le type de recours "
            ></v-autocomplete>
            <v-autocomplete
              v-if="this.$store.getters['Auth/getListDoc']"
              :items="this.$store.getters['Auth/getListDoc']"
              item-text="object"
              v-model="recourse.doc_id"
              item-value="id"
              outlined
              label="Choisir le dossier "
            ></v-autocomplete>
            <v-file-input
              multiple
              v-model="recourse.file"
              label="Inserer le fichier"
              truncate-length="15"
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">{{ text }}</v-chip>
              </template>
            </v-file-input>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="addRecourse" class="white--text" block>
              <v-icon class="mr-2" dark>mdi-cloud-upload</v-icon>Soumettre
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="8">
        <v-card>
          <v-card-title>
            <h4>Liste de mes recours</h4>
            <v-spacer></v-spacer>
            <v-chip class="ma-2">{{
              this.$store.getters["Auth/getAllRecourse"].data.length
            }}</v-chip>
          </v-card-title>
          <v-divider></v-divider>
          <v-virtual-scroll
            :items="this.$store.getters['Auth/getAllRecourse'].data"
            :item-height="60"
            height="410"
          >
            <template v-slot="{ item }">
              <v-list-item class="mb-2">
                <v-list-item-avatar>
                  <v-icon large>mdi-mail</v-icon>
                </v-list-item-avatar>

                <v-list-item-content class="d-flex justify-center">
                  <v-list-item-title>{{ item.object }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.document.object }}</v-list-item-subtitle>
                  <v-list-item-subtitle>{{
                    item.createdAt | moment("dddd, Do MMMM YYYY à HH:mm:ss A")
                  }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn @click="seeRecourse(item)" depressed small>
                    voir
                    <v-icon color="orange darken-4" right>mdi-eye</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-virtual-scroll>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="profile == 3">
      <v-col>
        <v-data-table
          locale="fr"
          :headers="headers"
          @click:row="seeRecourse"
          no-data-text="Aucun recours disponible"
          :items="this.$store.getters['Auth/getAllRecourse'].data"
          :search="search"
          sort-by="title"
          class="elevation-1 mb-5 px-5"
        >
          <template v-slot:[`item.status`]="{}">
            <v-icon medium color="red darken-2">mdi-lock</v-icon>
          </template>
          <template v-slot:[`item.openSession`]="{ item }">{{
            item.openSession ? item.openSession : "--" | moment("dddd, Do MMMM YYYY à HH:mm:ss A")
          }}</template>
          <template v-slot:[`item.createdAt`]="{ item }">{{
            item.createdAt ? item.createdAt : "--" | moment("dddd, Do MMMM YYYY à HH:mm:ss A")
          }}</template>
          <template v-slot:top>
            <v-toolbar flat color="white" class="mb-5">
              <v-toolbar-title>Liste des recours</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Recherchez un recours"
                single-line
                hide-details
              ></v-text-field>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    search: "",
    initial: false,
    headers: [
      {
        text: "Objet",
        align: "start",
        sortable: true,
        value: "object"
      },
      {
        text: "Type",
        align: "start",
        sortable: true,
        value: "recourseType.description"
      },
      {
        text: "Document en rapport",
        sortable: true,
        value: "document.object"
      },
      {
        text: "Email du concernée",
        sortable: true,
        value: "createdBy.email"
      },
      {
        text: "Date",
        sortable: true,
        value: "createdAt"
      }
    ],
    recourse: {
      object: "",
      doc_id: 0,
      recourse_type: 0,
      file: []
    }
  }),
  created() {
    let Virt = [];

    Virt = JSON.parse(localStorage.getItem("userData"));
    this.profile = Virt.profile.status;
    this.user_data = Virt;
    this.$store.dispatch("Auth/loadAllRecourse");
    this.$store.dispatch("Auth/loadAllDoc");
  },
  methods: {
    addRecourse() {
      let data = new FormData();
      data.append("object", this.recourse.object);
      data.append("document_id", this.recourse.doc_id);
      data.append("recourse_type", this.recourse.recourse_type);
      data.append("file", this.recourse.file[0]);

      this.$store.dispatch("Auth/addRecourse", data);

      this.recourse.object = "";
      this.recourse.doc_id = 0;
      this.recourse.recourse_type = 0;
      this.recourse.file = [];
    },
    seeRecourse(item) {
      const { id } = item;
      this.$router.push({ name: "showRecourse", params: { id: parseInt(id) } });
    }
  }
};
</script>
