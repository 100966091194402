<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="4">
        <v-card class="pa-2">
          <v-card-title>
            <h4>Informations</h4>
            <v-spacer></v-spacer>
            <v-chip label small color="primary">
              {{
                this.$store.getters["Auth/get_selected_recourse"].recourse.recourseType.description
              }}
            </v-chip>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            Objet du Recours :
            {{ this.$store.getters["Auth/get_selected_recourse"].recourse.object }}
            <br />
            Date d'emission :
            {{
              this.$store.getters["Auth/get_selected_recourse"].recourse.createdAt
                | moment("dddd, Do MMMM YYYY à HH:mm:ss")
            }}
            <v-divider class="my-2"></v-divider>
            <b>INFORMATION SUR LE DOCUMENT EN RAPPORT</b>
            <v-divider class="my-2"></v-divider>
            <b>Objet :</b>
            {{ this.$store.getters["Auth/get_selected_recourse"].recourse.document.object }}
            <v-spacer class="my-1"></v-spacer>
            <b>Date de publication :</b>
            {{
              this.$store.getters["Auth/get_selected_recourse"].recourse.document.publiDate
                | moment("dddd, Do MMMM YYYY à HH:mm:ss")
            }}
            <v-spacer class="my-1"></v-spacer>
            <b>Date de fin de dépot :</b>
            {{
              this.$store.getters["Auth/get_selected_recourse"].recourse.document.deadline
                | moment("dddd, Do MMMM YYYY à HH:mm:ss")
            }}
            <v-spacer class="my-1"></v-spacer>
            <b>Date d'ouverture des offres :</b>
            {{
              this.$store.getters["Auth/get_selected_recourse"].recourse.document.date_opening
                | moment("dddd, Do MMMM YYYY à HH:mm:ss")
            }}
            <v-spacer class="my-1"></v-spacer>
            <b>Type de document :</b>
            {{
              this.$store.getters["Auth/get_selected_recourse"].recourse.document.documentType
                .description
            }}
            <br />
          </v-card-text>
        </v-card>
        <v-card
          class="pa-2 mt-4"
          v-if="this.$store.getters['Auth/get_selected_recourse'].response.length > 0"
        >
          <v-card-title>
            <h4>Reponse au recours</h4>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-list-item
                class="block text-truncate"
                v-for="item in this.$store.getters['Auth/get_selected_recourse'].response"
                :key="item.id"
              >
                <v-list-item-content>
                  <v-list-item-title
                    class="primary--text text-truncate font-weight-bold"
                    v-text="item.object"
                  ></v-list-item-title>
                  <v-list-item-subtitle class="text-truncate">
                    {{ item.createdAt | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z") }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn icon @click="ShowResponse()">
                    <v-icon color="grey lighten-1">mdi-eye</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="mt-4" v-if="profile == 3">
          <v-card-title>
            Répondre au recours
          </v-card-title>
          <v-card-text>
            <v-text-field v-model="recourse.objet" label="Object"></v-text-field>
            <v-file-input
              placeholder="Charger le fichier"
              label="Fichier Demandes"
              multiple
              v-model="recourse.file"
              prepend-icon="mdi-paperclip"
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">{{ text }}</v-chip>
              </template>
            </v-file-input>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" block class="white--text" @click="addRecourseResp">
              <v-icon class="mr-2" dark>mdi-cloud-upload</v-icon>Soumettre
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" v-if="!showresponse">
        <v-card>
          <v-card-title>
            <h4>Aperçu du recours</h4>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 62em !important; overflow: hidden">
            <VueDocPreview
              style="height: 100%"
              v-if="
                this.$store.getters['Auth/get_selected_recourse'].recourse.fileType == 'doc' ||
                  this.$store.getters['Auth/get_selected_recourse'].recourse.fileType == 'docx' ||
                  this.$store.getters['Auth/get_selected_recourse'].recourse.fileType == 'xls' ||
                  this.$store.getters['Auth/get_selected_recourse'].recourse.fileType == 'xlsx' ||
                  this.$store.getters['Auth/get_selected_recourse'].recourse.fileType == 'XLS' ||
                  this.$store.getters['Auth/get_selected_recourse'].recourse.fileType == 'XLSX'
              "
              :value="this.$store.getters['Auth/get_selected_recourse'].recourse.fileLink"
              type="office"
            />
            <vue-friendly-iframe
              v-if="this.$store.getters['Auth/get_selected_recourse'].recourse.fileType == 'pdf'"
              :src="this.$store.getters['Auth/get_selected_recourse'].recourse.fileLink"
              frameborder="0"
              loading="lazy"
            ></vue-friendly-iframe>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" v-if="showresponse">
        <v-card>
          <v-card-title>
            <h4>Aperçu de la reponse au recours</h4>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 62em !important; overflow: hidden">
            <VueDocPreview
              style="height: 100%"
              v-if="
                this.$store.getters['Auth/get_selected_recourse'].response[0].fileType == 'doc' ||
                  this.$store.getters['Auth/get_selected_recourse'].response[0].fileType ==
                    'docx' ||
                  this.$store.getters['Auth/get_selected_recourse'].response[0].fileType == 'xls' ||
                  this.$store.getters['Auth/get_selected_recourse'].response[0].fileType ==
                    'xlsx' ||
                  this.$store.getters['Auth/get_selected_recourse'].response[0].fileType == 'XLS' ||
                  this.$store.getters['Auth/get_selected_recourse'].response[0].fileType == 'XLSX'
              "
              :value="this.$store.getters['Auth/get_selected_recourse'].response[0].fileLink"
              type="office"
            />
            <vue-friendly-iframe
              v-if="this.$store.getters['Auth/get_selected_recourse'].response[0].fileType == 'pdf'"
              :src="this.$store.getters['Auth/get_selected_recourse'].response[0].fileLink"
              frameborder="0"
              loading="lazy"
            ></vue-friendly-iframe>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { mapActions } from "vuex";
import VueDocPreview from "vue-doc-preview";

export default {
  components: { DatePicker, VueDocPreview },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    showresponse: false,
    recourse: {
      objet: "",
      file: []
    }
  }),
  created() {
    let Virt = [];
    Virt = JSON.parse(localStorage.getItem("userData"));
    this.profile = Virt.profile.status;
    this.user_data = Virt;
    this.$store.dispatch("Auth/loadAllRecourse");
    this.$store.dispatch("Auth/getRecourse", this.id);
  },
  methods: {
    ...mapActions({
      extension_addD: "Auth/extension_addD"
    }),
    ShowResponse() {
      this.showresponse = !this.showresponse;
    },
    addRecourseResp() {
      const data = new FormData();
      data.append("object", this.recourse.objet);
      data.append("recourse_id", this.id);
      if (this.recourse.file) {
        data.append("file", this.recourse.file[0]);
      } else {
        data.append("file", null);
      }
      this.$store.dispatch("Auth/addRecourseResp", data);
      this.recourse.objet = "";
      this.recourse.file = [];
    }
  }
};
</script>
<style>
.w-62em {
  height: 62em !important;
  width: 100% !important;
}
.v-card__text {
  width: 100% !important;
}
.vue-friendly-iframe iframe {
  width: 100% !important;
  height: 62em !important;
}
</style>
